import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ObjetivoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ObjetivoPage",
            ss: ss,
            ajax: {
                "url": ss.indexObjetivo(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'ObjetivoGeneral', name: 'ObjetivoGeneral', title: 'Objetivo General' },
                { data: 'Politica', name: 'Politica', title: 'Politica' },
                { data: 'Normativa', name: 'Normativa', title: 'Normativa' },
                { data: 'Reglamento', name: 'Reglamento', title: 'Reglamento' },
                { data: 'ObjetivoEspecifico', name: 'ObjetivoEspecifico', title: 'Objetivo Especifico' },
                // { data: 'Analisis', name: 'Analisis', title: 'Etapa' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            objetivos: [],
            objetivo: {},
            isLoading: false,
            errorBag: {},
            etapas: {}
        };
    },
    methods: {
        getEtapa() {
            this.ss.listEtapa().then(
                (result) => {
                    let response = result.data;
                    this.etapas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newObjetivo() {
            this.objetivo = {};
            this.$refs['frm-objetivo'].show();
        },
        showObjetivo(id) {
            this.isLoading=true;
            this.ss.showObjetivo(id).then(
                (result) => {
                    let response = result.data;
                    this.objetivo = response.data;
                    this.$refs['view-objetivo'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editObjetivo() {
            this.$refs['frm-objetivo'].show();
            this.$refs['view-objetivo'].hide();
        },
        cancelObjetivo() {
            if (this.objetivo.id) {
                this.$refs['view-objetivo'].show();
            }
            this.$refs['frm-objetivo'].hide();
        },
        saveObjetivo() {
            this.ss.storeObjetivo(this.objetivo).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-objetivo'].hide();
                    this.$refs['datatable-objetivo'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteObjetivo() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyObjetivo(this.objetivo)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-objetivo'].hide();
                                this.$refs['datatable-objetivo'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Objetivo').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showObjetivo(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else {
            this.getEtapa();
        }
    }
};
