var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    showTipoSistema(id) {
        return axios.get('api/TipoSistema/show?id=' + id);
    }

    indexTipoSistema() {
        return axios.defaults.baseURL + 'api/TipoSistema/index';
    }

    listTipoSistema() {
        return axios.get('api/TipoSistema/list');
    }

    storeTipoSistema(item) {
        return axios.post('api/TipoSistema/store', item);
    }

    destroyTipoSistema(item) {
        return axios.post('api/TipoSistema/destroy', item);
    }
    
    showRiesgo(id) {
        return axios.get('api/Riesgo/show?id=' + id);
    }

    indexRiesgo() {
        return axios.defaults.baseURL + 'api/Riesgo/index';
    }

    listRiesgo() {
        return axios.get('api/Riesgo/list');
    }

    storeRiesgo(item) {
        return axios.post('api/Riesgo/store', item);
    }

    destroyRiesgo(item) {
        return axios.post('api/Riesgo/destroy', item);
    }
    
    showModulo(id) {
        return axios.get('api/Modulo/show?id=' + id);
    }

    indexModulo() {
        return axios.defaults.baseURL + 'api/Modulo/index';
    }

    listModulo() {
        return axios.get('api/Modulo/list');
    }

    storeModulo(item) {
        return axios.post('api/Modulo/store', item);
    }

    destroyModulo(item) {
        return axios.post('api/Modulo/destroy', item);
    }    

    showModuloResponsable(id) {
        return axios.get('api/ModuloResponsable/show?id=' + id);
    }

    indexModuloResponsable() {
        return axios.defaults.baseURL + 'api/ModuloResponsable/index';
    }

    listModuloResponsable() {
        return axios.get('api/ModuloResponsable/list');
    }

    storeModuloResponsable(item) {
        return axios.post('api/ModuloResponsable/store', item);
    }

    destroyModuloResponsable(item) {
        return axios.post('api/ModuloResponsable/destroy', item);
    }

    showModuloRol(id) {
        return axios.get('api/ModuloRol/show?id=' + id);
    }

    indexModuloRol() {
        return axios.defaults.baseURL + 'api/ModuloRol/index';
    }

    listModuloRol() {
        return axios.get('api/ModuloRol/list');
    }

    storeModuloRol(item) {
        return axios.post('api/ModuloRol/store', item);
    }

    destroyModuloRol(item) {
        return axios.post('api/ModuloRol/destroy', item);
    }

    showResponsable(id) {
        return axios.get('api/Responsable/show?id=' + id);
    }

    indexResponsable() {
        return axios.defaults.baseURL + 'api/Responsable/index';
    }

    listResponsable() {
        return axios.get('api/Responsable/list');
    }

    storeResponsable(item) {
        return axios.post('api/Responsable/store', item);
    }

    destroyResponsable(item) {
        return axios.post('api/Responsable/destroy', item);
    }
    
    showSistema(id) {
        return axios.get('api/Sistema/show?id=' + id);
    }

    indexSistema() {
        return axios.defaults.baseURL + 'api/Sistema/index';
    }

    generalSistema() {
        return axios.defaults.baseURL + 'api/Sistema/general';
    }

    listSistema() {
        return axios.get('api/Sistema/list');
    }

    storeSistema(item) {
        return axios.post('api/Sistema/store', item);
    }

    destroySistema(item) {
        return axios.post('api/Sistema/destroy', item);
    }
    
    showReporte(id) {
        return axios.get('api/Reporte/show?id=' + id);
    }

    indexReporte() {
        return axios.defaults.baseURL + 'api/Reporte/index';
    }

    listReporte() {
        return axios.get('api/Reporte/list');
    }

    storeReporte(item) {
        return axios.post('api/Reporte/store', item);
    }

    destroyReporte(item) {
        return axios.post('api/Reporte/destroy', item);
    }
    
    showSeguridad(id) {
        return axios.get('api/Seguridad/show?id=' + id);
    }

    indexSeguridad() {
        return axios.defaults.baseURL + 'api/Seguridad/index';
    }

    listSeguridad() {
        return axios.get('api/Seguridad/list');
    }

    storeSeguridad(item) {
        return axios.post('api/Seguridad/store', item);
    }

    destroySeguridad(item) {
        return axios.post('api/Seguridad/destroy', item);
    }
    
    showDocumentacion(id) {
        return axios.get('api/Documentacion/show?id=' + id);
    }

    indexDocumentacion() {
        return axios.defaults.baseURL + 'api/Documentacion/index';
    }

    listDocumentacion() {
        return axios.get('api/Documentacion/list');
    }

    storeDocumentacion(item) {
        return axios.post('api/Documentacion/store', item);
    }

    destroyDocumentacion(item) {
        return axios.post('api/Documentacion/destroy', item);
    }
    showProductoFinal(id) {
        return axios.get('api/ProductoFinal/show?id=' + id);
    }

    indexProductoFinal() {
        return axios.defaults.baseURL + 'api/ProductoFinal/index';
    }

    listProductoFinal() {
        return axios.get('api/ProductoFinal/list');
    }

    storeProductoFinal(item) {
        return axios.post('api/ProductoFinal/store', item);
    }

    destroyProductoFinal(item) {
        return axios.post('api/ProductoFinal/destroy', item);
    }

    showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }

    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }

    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }

    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }

    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }

    showTipoDocumento(id) {
        return axios.get('api/TipoDocumento/show?id=' + id);
    }

    indexTipoDocumento() {
        return axios.defaults.baseURL + 'api/TipoDocumento/index';
    }

    listTipoDocumento() {
        return axios.get('api/TipoDocumento/list');
    }

    storeTipoDocumento(item) {
        return axios.post('api/TipoDocumento/store', item);
    }

    destroyTipoDocumento(item) {
        return axios.post('api/TipoDocumento/destroy', item);
    }
    
    showEtapa(id) {
        return axios.get('api/Etapa/show?id=' + id);
    }

    indexEtapa() {
        return axios.defaults.baseURL + 'api/Etapa/index';
    }

    listEtapa() {
        return axios.get('api/Etapa/list');
    }

    storeEtapa(item) {
        return axios.post('api/Etapa/store', item);
    }

    destroyEtapa(item) {
        return axios.post('api/Etapa/destroy', item);
    }
    
    showObjetivo(id) {
        return axios.get('api/Objetivo/show?id=' + id);
    }

    indexObjetivo() {
        return axios.defaults.baseURL + 'api/Objetivo/index';
    }

    listObjetivo() {
        return axios.get('api/Objetivo/list');
    }

    storeObjetivo(item) {
        return axios.post('api/Objetivo/store', item);
    }

    destroyObjetivo(item) {
        return axios.post('api/Objetivo/destroy', item);
    }
    
    showPersonaPresupuesto(id) {
        return axios.get('api/PersonaPresupuesto/show?id=' + id);
    }

    indexPersonaPresupuesto() {
        return axios.defaults.baseURL + 'api/PersonaPresupuesto/index';
    }

    listPersonaPresupuesto() {
        return axios.get('api/PersonaPresupuesto/list');
    }

    storePersonaPresupuesto(item) {
        return axios.post('api/PersonaPresupuesto/store', item);
    }

    destroyPersonaPresupuesto(item) {
        return axios.post('api/PersonaPresupuesto/destroy', item);
    }
    showEvaluacion(id) {
        return axios.get('api/Evaluacion/show?id=' + id);
    }

    indexEvaluacion() {
        return axios.defaults.baseURL + 'api/Evaluacion/index';
    }

    listEvaluacion() {
        return axios.get('api/Evaluacion/list');
    }

    storeEvaluacion(item) {
        return axios.post('api/Evaluacion/store', item);
    }

    destroyEvaluacion(item) {
        return axios.post('api/Evaluacion/destroy', item);
    }

    showPresupuesto(id) {
        return axios.get('api/Presupuesto/show?id=' + id);
    }

    indexPresupuesto() {
        return axios.defaults.baseURL + 'api/Presupuesto/index';
    }

    listPresupuesto() {
        return axios.get('api/Presupuesto/list');
    }

    storePresupuesto(item) {
        return axios.post('api/Presupuesto/store', item);
    }

    destroyPresupuesto(item) {
        return axios.post('api/Presupuesto/destroy', item);
    }

    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }
    
    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    showOrganizacion(id) {
        return axios.get('api/Organizacion/show?id=' + id);
    }

    indexOrganizacion() {
        return axios.defaults.baseURL + 'api/Organizacion/index';
    }

    listOrganizacion() {
        return axios.get('api/Organizacion/list');
    }

    storeOrganizacion(item) {
        return axios.post('api/Organizacion/store', item);
    }

    destroyOrganizacion(item) {
        return axios.post('api/Organizacion/destroy', item);
    }
    indexRepositorio() {
        return axios.defaults.baseURL + 'api/Repositorio/index';
    }

    listRepositorio() {
        return axios.get('api/Repositorio/list');
    }

    storeRepositorio(item) {
        return axios.post('api/Repositorio/store', item);
    }

    destroyRepositorio(item) {
        return axios.post('api/Repositorio/destroy', item);
    }

    showRepositorio(id) {
        return axios.get('api/Repositorio/show?id=' + id);
    }
    updateProduction(id) {
        return axios.get('api/Repositorio/updateProduction?id=' + id);
    }
    updateBD(id) {
        return axios.get('api/Repositorio/updateBD?id=' + id);
    }
    updateEnv(item) {
        return axios.post('api/Repositorio/updateEnv',item);
    }
    //
    indexLenguaje() {
        return axios.defaults.baseURL + 'api/Lenguaje/index';
    }

    listLenguaje() {
        return axios.get('api/Lenguaje/list');
    }

    storeLenguaje(item) {
        return axios.post('api/Lenguaje/store', item);
    }

    destroyLenguaje(item) {
        return axios.post('api/Lenguaje/destroy', item);
    }

    showLenguaje(id) {
        return axios.get('api/Lenguaje/show?id=' + id);
    }

}