<template>
  <v-list nav dense>
    <v-list-item-group>
      <router-link
        class="black--text text--accent-4"
        to="Sistema"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.Sistema") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <v-list-group group="sistema">

        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-settings-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sistema</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-group group="sistemaGeneral" sub-group="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi mdi-account-settings-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>General</v-list-item-title>
            </v-list-item-content>
          </template>
          <router-link
            class="black--text text--accent-4"
            to="Objetivo"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Objetivo") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Sistema"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Sistema") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Responsable"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("nav.Responsable")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>

        <v-list-group group="sistemaEstructura" sub-group="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi mdi-account-settings-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Estructura</v-list-item-title>
            </v-list-item-content>
          </template>
          <router-link
            class="black--text text--accent-4"
            to="Modulo"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Modulo") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Etapa"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Etapa") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Reporte"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Reporte") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>

        <v-list-group group="sistemaDocumento" sub-group="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi mdi-account-settings-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Documentos</v-list-item-title>
            </v-list-item-content>
          </template>
          <router-link
            class="black--text text--accent-4"
            to="Reporte"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Reporte") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Documentacion"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("nav.Documentacion")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>

        <v-list-group group="sistemaEspecifico" sub-group="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi mdi-account-settings-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Específico</v-list-item-title>
            </v-list-item-content>
          </template>
          <router-link
            class="black--text text--accent-4"
            to="Riesgo"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t("nav.Riesgo") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="ProductoFinal"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("nav.ProductoFinal")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Presupuesto"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("nav.Presupuesto")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
          <router-link
            class="black--text text--accent-4"
            to="Evaluacion"
            active-class="blue--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("nav.Evaluacion")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </v-list-group>
      </v-list-group>

      <v-list-group group="parametricas">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-settings-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Paramétricas</v-list-item-title>
          </v-list-item-content>
        </template>

        <router-link
          class="black--text text--accent-4"
          to="TipoSistema"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.TipoSistema") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="UnidadAcademica"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("nav.UnidadAcademica")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="Rol"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.Rol") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="TipoDocumento"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("nav.TipoDocumento")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="Especialidad"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("nav.Especialidad")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="TipoReporte"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.TipoReporte") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>        
        <router-link
          class="black--text text--accent-4"
          to="Organizacion"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.Organizacion") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list-group>

      <v-list-group group="usuariosSistema">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-settings-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Usuarios del Sistema</v-list-item-title>
          </v-list-item-content>
        </template>

        <router-link
          class="black--text text--accent-4"
          to="Persona"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.Persona") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list-group>

      <v-list-group group="accesosModulos">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-settings-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Accesos Modulos</v-list-item-title>
          </v-list-item-content>
        </template>

        <router-link
          class="black--text text--accent-4"
          to="ModuloRol"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.ModuloRol") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <router-link
          class="black--text text--accent-4"
          to="ModuloResponsable"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $t("nav.ModuloResponsable")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list-group>

      <v-list-group group="desarrollo">
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-settings-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Desarrollo</v-list-item-title>
          </v-list-item-content>
        </template>

        <router-link
          class="black--text text--accent-4"
          to="Repositorio"
          active-class="blue--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t("nav.Repositorio") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list-group>

      <router-link
        class="black--text text--accent-4"
        to="Reportes"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("nav.Reportes") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: "MenuVuetify",
  data() {
    return {
      msg: "MenuVuetify"
    };
  },
};
</script>