import Vue from "vue";
import App from "./App";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./i18n";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "sweetalert2/dist/sweetalert2.min.css";
import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";
import VueSocketIO from "vue-socket.io";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(
  new VueSocketIO({
    debug: true,
    //connection: process.env.SOCKET_MAIN_SERVICE,
    connection: "https://mensajeria-emi-socket.herokuapp.com/"
  })
);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
});
