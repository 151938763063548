import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import TipoSistemaPage from '@/pages/TipoSistema/TipoSistemaPage.vue'
import SistemaPage from '@/pages/Sistema/SistemaPage.vue'
import GeneralPage from '@/pages/General/GeneralPage.vue'
import ReportePage from '@/pages/Reporte/ReportePage.vue'
import SeguridadPage from '@/pages/Seguridad/SeguridadPage.vue'
import PersonaPresupuestoPage from '@/pages/PersonaPresupuesto/PersonaPresupuestoPage.vue'
import DocumentacionPage from '@/pages/Documentacion/DocumentacionPage.vue'
import RiesgoPage from '@/pages/Riesgo/RiesgoPage.vue'
import ResponsablePage from '@/pages/Responsable/ResponsablePage.vue'
import ModuloPage from '@/pages/Modulo/ModuloPage.vue'
import ModuloResponsablePage from '@/pages/ModuloResponsable/ModuloResponsablePage.vue'
import ModuloRolPage from '@/pages/ModuloRol/ModuloRolPage.vue'
import ProductoFinalPage from '@/pages/ProductoFinal/ProductoFinalPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'
import TipoDocumentoPage from '@/pages/TipoDocumento/TipoDocumentoPage.vue'
import EtapaPage from '@/pages/Etapa/EtapaPage.vue'
import ObjetivoPage from '@/pages/Objetivo/ObjetivoPage.vue'
import EvaluacionPage from '@/pages/Evaluacion/EvaluacionPage.vue'
import PresupuestoPage from '@/pages/Presupuesto/PresupuestoPage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import OrganizacionPage from '@/pages/Organizacion/OrganizacionPage.vue'
import RepositorioPage from '@/pages/Repositorio/RepositorioPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/General',
      name: 'General',
      component: GeneralPage
    },
    {
      path: '/TipoSistema',
      name: 'TipoSistema',
      component: TipoSistemaPage
    },    
    {
      path: '/Riesgo',
      name: 'Riesgo',
      component: RiesgoPage
    },    
    {
      path: '/Responsable',
      name: 'Responsable',
      component: ResponsablePage
    },    
    {
      path: '/Modulo',
      name: 'Modulo',
      component: ModuloPage
    },    
    {
      path: '/ModuloResponsable',
      name: 'ModuloResponsable',
      component: ModuloResponsablePage
    }, 
    {
      path: '/ModuloRol',
      name: 'ModuloRol',
      component: ModuloRolPage
    },     
    {
      path: '/ProductoFinal',
      name: 'ProductoFinal',
      component: ProductoFinalPage
    }, 
    {
      path: '/Sistema',
      name: 'Sistema',
      component: SistemaPage
    },
    {
      path: '/Reporte',
      name: 'Reporte',
      component: ReportePage
    },    
    {
      path: '/Seguridad',
      name: 'Seguridad',
      component: SeguridadPage
    },
    
    {
      path: '/Documentacion',
      name: 'Documentacion',
      component: DocumentacionPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/TipoDocumento',
      name: 'TipoDocumento',
      component: TipoDocumentoPage
    },
    {
      path: '/Etapa',
      name: 'Etapa',
      component: EtapaPage
    },        
    {
      path: '/Objetivo',
      name: 'Objetivo',
      component: ObjetivoPage
    }, 
    {
      path: '/PersonaPresupuesto',
      name: 'PersonaPresupuesto',
      component: PersonaPresupuestoPage
    }, 
    {
      path: '/Presupuesto',
      name: 'Presupuesto',
      component: PresupuestoPage
    },    
    {
      path: '/Evaluacion',
      name: 'Evaluacion',
      component: EvaluacionPage
    },    
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },    
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },    
    {
      path: '/Organizacion',
      name: 'Organizacion',
      component: OrganizacionPage
    },
    {
      path: '/Repositorio',
      name: 'Repositorio',
      component: RepositorioPage
    }
  ]
})