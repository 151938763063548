import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaPresupuestoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaPresupuestoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersonaPresupuesto(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Persona', name: 'Persona', title: 'Persona' },
                { data: 'Costo', name: 'Costo', title: 'Presupuesto' },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personapresupuestos: [],
            personapresupuesto: {},
            isLoading: false,
            errorBag: {},
            personas: {},
            presupuestos: {},
            especialidads: {}
        };
    },
    methods: {
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPresupuesto() {
            this.ss.listPresupuesto().then(
                (result) => {
                    let response = result.data;
                    this.presupuestos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidads = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersonaPresupuesto() {
            this.personapresupuesto = {};
            this.$refs['frm-personapresupuesto'].show();
        },
        showPersonaPresupuesto(id) {
            this.isLoading=true;
            this.ss.showPersonaPresupuesto(id).then(
                (result) => {
                    let response = result.data;
                    this.personapresupuesto = response.data;
                    this.$refs['view-personapresupuesto'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPersonaPresupuesto() {
            this.$refs['frm-personapresupuesto'].show();
            this.$refs['view-personapresupuesto'].hide();
        },
        cancelPersonaPresupuesto() {
            if (this.personapresupuesto.id) {
                this.$refs['view-personapresupuesto'].show();
            }
            this.$refs['frm-personapresupuesto'].hide();
        },
        savePersonaPresupuesto() {
            this.ss.storePersonaPresupuesto(this.personapresupuesto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personapresupuesto'].hide();
                    this.$refs['datatable-personapresupuesto'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersonaPresupuesto() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersonaPresupuesto(this.personapresupuesto)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personapresupuesto'].hide();
                                this.$refs['datatable-personapresupuesto'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-PersonaPresupuesto').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersonaPresupuesto(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getPersona();
            this.getPresupuesto();
            this.getEspecialidad();
        }
    }
};
