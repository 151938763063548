import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "SistemaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "SistemaPage",
            ss: ss,
            ajax: {
                "url": ss.indexSistema(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Titulo', name: 'Titulo', title: 'Título' },
                { data: 'Codigo', name: 'Codigo', title: 'Código' },
                { data: 'VersionActual', name: 'VersionActual', title: 'Versión Actual' },
                { data: 'FechaElaboracion', name: 'FechaElaboracion', title: 'Fecha Elaboración' },
                { data: 'VigenteDesde', name: 'VigenteDesde', title: 'Vigente Desde' },
                { data: 'Persona', name: 'Persona', title: 'Aprobado Por' },
              //{ data: 'Tipo', name: 'Tipo', title: 'Tipo Sistema' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                { data: 'Logo', name: 'Logo', title: 'Logo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            sistemas: [],
            sistema: {},
            isLoading: false,
            errorBag: {},
            objetivos: {},
            tiposistemas: {},
            personas: {}
        };
    },
    methods: {       
        getObjetivo() {
            this.ss.listObjetivo().then(
                (result) => {
                    let response = result.data;
                    this.objetivos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoSistema() {
            this.ss.listTipoSistema().then(
                (result) => {
                    let response = result.data;
                    this.tiposistemas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newSistema() {
            this.sistema = {};
            this.$refs['frm-sistema'].show();
        },
        showSistema(id) {
            this.isLoading=true;
            this.ss.showSistema(id).then(
                (result) => {
                    let response = result.data;
                    this.sistema = response.data;
                    this.$refs['view-sistema'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editSistema() {
            this.$refs['frm-sistema'].show();
            this.$refs['view-sistema'].hide();
        },
        cancelSistema() {
            if (this.sistema.id) {
                this.$refs['view-sistema'].show();
            }
            this.$refs['frm-sistema'].hide();
        },
        saveSistema() {
            this.ss.storeSistema(this.sistema).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response.data.id);
                    this.$refs['frm-sistema'].hide();
                    this.$refs['datatable-sistema'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteSistema() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroySistema(this.sistema)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-sistema'].hide();
                                this.$refs['datatable-sistema'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Sistema').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showSistema(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getObjetivo();
            this.getTipoSistema();
            this.getPersona();
        }
    }
};
