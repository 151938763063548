import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "RepositorioPage",
    data() {
        let ss = new MainService();
        return {
            msg: "RepositorioPage",
            ss: ss,
            ajax: {
                "url": ss.indexRepositorio(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Repositorio', name: 'Repositorio', title: 'Repositorio' },
                { data: 'Titulo', name: 'Sistema', title: 'Sistema' },
                { data: 'Gitlab', name: 'Gitlab', title: 'Gitlab' },
                { data: 'Lenguaje', name: 'Lenguaje', title: 'Lenguaje' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            Repositorios: [],
            Repositorio: {},
            isLoading: false,
            errorBag: {},
            Sistemas: [],
            Lenguajes: [],
            env: ""
        };
    },
    methods: {
        getSistema() {
            this.ss.listSistema().then(
                (result) => {
                    let response = result.data;
                    this.Sistemas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getLenguaje() {
            this.ss.listLenguaje().then(
                (result) => {
                    let response = result.data;
                    this.Lenguajes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newRepositorio() {
            this.Repositorio = {};
            this.$refs['frm-Repositorio'].show();
        },
        editEnv() {
            this.$refs['frm-envfile'].show();
            this.$refs['view-Repositorio'].hide();
        },
        saveEnv() {
            let element = {
                id: this.Repositorio.id,
                data: this.env
            }
            console.log(element);
            this.ss.updateEnv(element).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-envfile'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelEnv() {
            if (this.Repositorio.id) {
                this.$refs['view-Repositorio'].show();
            }
            this.$refs['frm-envfile'].hide();
        },
        showRepositorio(id) {
            this.isLoading = true;
            this.ss.showRepositorio(id).then(
                (result) => {
                    let response = result.data;
                    this.Repositorio = response.data;
                    this.$refs['view-Repositorio'].show();
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;
            });
        },
        updateBD() {
            this.isLoading = true;
            this.ss.updateBD(this.Repositorio.id).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al realizar la acción, por favor vuelva a realizarlo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
            this.isLoading = false;
        },
        updateProduction() {
            this.isLoading = true;
            this.ss.updateProduction(this.Repositorio.id).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.isLoading = false;
                    this.$bvToast.toast(
                        'Problema al realizar la acción, por favor vuelva a realizarlo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
            this.isLoading = false;
        },
        editRepositorio() {
            this.$refs['frm-Repositorio'].show();
            this.$refs['view-Repositorio'].hide();
        },
        cancelRepositorio() {
            if (this.Repositorio.id) {
                this.$refs['view-Repositorio'].show();
            }
            this.$refs['frm-Repositorio'].hide();
        },
        saveRepositorio() {
            if (!this.Repositorio.Production) {
                this.Repositorio.Production = false;
            }
            console.log(this.Repositorio);
            this.ss.storeRepositorio(this.Repositorio).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-Repositorio'].hide();
                    this.$refs['datatable-Repositorio'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteRepositorio() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyRepositorio(this.Repositorio)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-Repositorio'].hide();
                                this.$refs['datatable-Repositorio'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Repositorio').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showRepositorio(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.getSistema();
            this.getLenguaje();
        }
    }
};
