import { render, staticRenderFns } from "./RiesgoPage.vue?vue&type=template&id=385e2b5a&"
import script from "./RiesgoPage.js?vue&type=script&lang=js&"
export * from "./RiesgoPage.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports